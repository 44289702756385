import { ReactElement, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, Tabs, Typography, message } from "antd";
import { differenceInCalendarDays } from "date-fns";
import { CheckOutlined } from "assets/icons/icons";
import { useGetBudget } from "app/utils/api/queries/budget.query";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import { ExclamationOutlined } from "assets";
import { IUser } from "app/store/types/user.types";
import EventInfoTable from "app/components/modules/budget/event_info_table/event_info";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import BudgetSheet from "app/components/modules/budget/budget_sheet/budget_sheet";
import { _isEmpty } from "app/utils/helpers";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import racesSlice from "app/store/races/races.slice";
import VarianceSheet from "app/components/modules/budget/variance_sheet/variance_sheet";
import User from "app/utils/user";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import URL from "app/constants/route_urls";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import "app/views/protected/budget/budget.scss";

const BudgetHeader = () => (
    <div className="budget__header page__header">
        <Typography.Title level={1}>Budget Builder</Typography.Title>

        <div className="page__header-desc">
            <Typography.Text>
                Plan your race budget and manage your financial projections.
            </Typography.Text>
        </div>
    </div>
);

const BudgetBuilder = (): ReactElement | null => {
    const { data: widgetRaces, isLoading: isWidgetRacesLoading } = useGetWidgetRaces({
        display: "menu",
    });
    const { currentRace } = racesSlice((state) => state);
    const { toggleImportRaceModal } = modalsSlice((state) => state);
    const racePk = currentRace.pk!;
    const {
        data,
        isLoading: isBudgetLoading,
        isError,
        error,
    } = useGetBudget(racePk, {
        enabled: !!currentRace.pk,
        refetchOnMount: true,
    });

    const isLoading = isBudgetLoading || isWidgetRacesLoading;

    const tabItems = [
        { key: "1", label: "Budget", children: <BudgetSheet data={data} /> },
        { key: "2", label: "Variance", children: <VarianceSheet data={data} /> },
    ];

    if (
        !isLoading &&
        (isError || (_isEmpty(currentRace) && !!widgetRaces && widgetRaces.length > 0))
    )
        return (
            <>
                <BudgetHeader />
                <div className="budget__error">
                    {error?.response?.status === 404 ? (
                        <Typography.Text>No budget available for this race.</Typography.Text>
                    ) : (
                        <Typography.Text>Something went wrong. Please try again.</Typography.Text>
                    )}
                </div>
            </>
        );

    if (!!widgetRaces && widgetRaces.length === 0 && !isWidgetRacesLoading) {
        return (
            <>
                <BudgetHeader />
                <div className="budget__error">
                    <Typography.Text>
                        To use this tool, please{" "}
                        <span onClick={toggleImportRaceModal} role="presentation" className="link">
                            add a race{" "}
                        </span>
                        first.
                    </Typography.Text>
                </div>
            </>
        );
    }

    return (
        <PageWithLoader isLoading={isLoading}>
            <div className="budget">
                <BudgetHeader />

                <div className="budget__event-info-table">
                    <EventInfoTable />
                </div>
                <Tabs defaultActiveKey="1" items={tabItems} />
            </div>
        </PageWithLoader>
    );
};

export default BudgetBuilder;
