import { ReactElement, useEffect, useMemo, useState } from "react";
import { Button, Input, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import Dialog from "app/components/elements/dialog/dialog";
import API_URL from "app/constants/api_urls";
import URL from "app/constants/route_urls";
import {
    IFormikResetPassword,
    UserPasswordResetSchema as validationSchema,
} from "app/lib/validation_schemas/auth.schema";
import userSlice from "app/store/user/user.slice";
import { _isEmpty } from "app/utils/helpers";
import { blurred, errorValidationMessage, inputError } from "app/utils/validation";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import logo from "assets/logo_full.svg";
import API from "app/utils/api/axios";
import "app/views/public/auth/auth.scss";

const { Text, Paragraph } = Typography;

function PasswordReset(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const cancelAxios = useMemo(() => axios.CancelToken.source(), []);

    // Store
    const { isAuthenticated } = userSlice((state) => state);

    // Clean up
    useEffect(() => () => cancelAxios.cancel(), [cancelAxios]);

    useEffect(() => {
        if (isAuthenticated) navigate(URL.DASHBOARD);
    }, [isAuthenticated, navigate]);

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm } =
        useFormik({
            initialValues: {
                email: Storage.get("login_email") || "",
            },
            validationSchema,
            onSubmit: async (values: { email: string }): Promise<void> => {
                try {
                    setError("");
                    setIsLoading(true);

                    const { email }: { [key: string]: string } = values;

                    const { data } = await API.post(
                        API_URL.PASSWORD_RESET,
                        { email },
                        { cancelToken: cancelAxios.token }
                    );

                    setIsLoading(false);

                    Storage.set(STORAGE_CONSTANTS.passwordResetMessage, data.detail);
                    Storage.remove("login_email");

                    resetForm();
                    navigate(URL.LOGIN);
                } catch (error) {
                    setIsLoading(false);
                    setError("Something went wrong. Please try again");
                }
            },
        } as IFormikResetPassword);

    // Display back-end errors
    const renderError = () => <Dialog type="error" message={error} />;

    // Button disabled status
    const buttonDisabled = (): boolean => {
        const checkValues = Object.values(values).some((val: string) => val.length === 0);
        if (isLoading || (errors && Object.keys(errors).length !== 0 && checkValues)) return true;
        return false;
    };

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href={import.meta.env.VITE_BASE_URL} rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="RDHQ - Main Logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {!_isEmpty(error) && renderError()}
                        <form onSubmit={handleSubmit}>
                            <div className="auth-wrap__form-group">
                                <Paragraph>
                                    To reset your password, please enter your email below.
                                </Paragraph>
                                <Text className="ant-label" strong>
                                    Email
                                </Text>
                                <Input
                                    name="email"
                                    placeholder="Your email address"
                                    status={
                                        inputError("email", errors, values, touched) ? "error" : ""
                                    }
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("email", event, errors);
                                    }}
                                    disabled={isLoading}
                                />
                                <span className="auth-wrap__span-error">
                                    {errorValidationMessage("email", errors, values, touched, {})}
                                </span>
                            </div>

                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isLoading}
                                    disabled={buttonDisabled()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text>
                        Back to{" "}
                        <Link
                            to={URL.LOGIN}
                            state={{ from: location?.state?.from }}
                            className="link"
                        >
                            sign in
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
