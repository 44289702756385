/* eslint-disable react/no-unstable-nested-components */
import { Button, message, Table, Typography } from "antd";
import { useState } from "react";
import { ChevronDownOutlined } from "assets/icons/icons";
import { BudgetType, BudgetItemsGroupType } from "app/types/budget/budget.types";
import { formatCurrency } from "app/utils/helpers/format_currency";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { obscureData } from "app/utils/helpers/obscure_data";
import ExportBudgetModal from "app/components/modules/budget/export_budget_modal/export_budget_modal";
import ObscuredBBWrapper from "app/components/modules/budget/obscured_bb_wrapper/obscured_bb_wrapper";
import userSlice from "app/store/user/user.slice";
import ProTooltip from "app/components/elements/wrappers/pro_tooltip";
import { groupObjectsByCategory } from "../table_utils";
import AddEditBudgetItemModal from "../add_edit_budget/add_edit_budget_item";
import BudgetTableHeader from "./budget_tabler_header";
import { budget_columns } from "./budget_columns";
import BudgetTableSummary from "./budget_table_summary";
import "app/components/modules/budget/budget_sheet/budget_sheet.scss";

const BudgetSheet = ({ data }: { data: BudgetType | undefined }) => {
    const { user } = userSlice((state) => state);
    const [editingItemPk, setEditingItemPk] = useState<number | null>(null);
    const [openGroups, setOpenGroups] = useState<string[]>([]);
    const [exportModalVisible, setExportModalVisible] = useState(false);

    const showNotEditableMessage = () => {
        message.info("You cannot edit or delete this item");
    };

    const editingItem = data?.items.find((item) => item.pk === editingItemPk);

    const canAccessBB = useCanAccessBB();

    const renderPremiumValue = (val: string) => (canAccessBB ? val : obscureData(val, "currency"));

    const renderBudgetGroupTable = (group: BudgetItemsGroupType) => {
        const isOpen = !openGroups.includes(group.name);

        return (
            <div key={group.name} className="budget-sheet__group-wrapper">
                <Table
                    className="budget-sheet__meta-table budget-sheet__header-table"
                    columns={budget_columns({ with: user, canAccessBB })}
                    showHeader={false}
                    summary={() => (
                        <BudgetTableSummary
                            items={{
                                0: (
                                    <Typography.Text className="budget-sheet__row-title">
                                        {group.name}{" "}
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (openGroups.includes(group.name)) {
                                                    setOpenGroups(
                                                        openGroups.filter(
                                                            (name) => name !== group.name
                                                        )
                                                    );
                                                } else {
                                                    setOpenGroups([...openGroups, group.name]);
                                                }
                                            }}
                                            className={isOpen ? "active" : ""}
                                        >
                                            <ChevronDownOutlined />
                                        </button>
                                    </Typography.Text>
                                ),
                            }}
                        />
                    )}
                />

                <Table
                    className={`budget-sheet__group-table ${isOpen ? "active" : ""}`}
                    columns={budget_columns({ with: user, canAccessBB })}
                    showHeader={false}
                    dataSource={group.items}
                    pagination={false}
                    rowKey={(record) => record?.pk}
                    size="small"
                    onRow={(record) => ({
                        onClick: () =>
                            record?.editable
                                ? setEditingItemPk(record?.pk)
                                : showNotEditableMessage(),
                        className: `table__clickable-row ${!record?.editable ? "notEditable" : ""}`,
                    })}
                />
            </div>
        );
    };

    const renderBudgeSheetByType = (type: "revenue" | "cost") => {
        const objects = data?.items.filter((item) => item.category?.type === type) || [];
        const groups = groupObjectsByCategory(objects);

        return (
            <>
                <BudgetTableHeader type={type} />

                {groups.map((group) => renderBudgetGroupTable(group))}

                {/* Empty placeholder table just to add white space */}
                {/* <Table
                    className="budget-sheet__meta-table budget-sheet__placeholder-table"
                    showHeader={false}
                    columns={budget_columns({ with: user, canAccessBB })}
                    summary={() => <BudgetTableSummary items={{}} />}
                /> */}

                <Table
                    className="budget-sheet__meta-table budget-sheet__meta-table--summary"
                    columns={budget_columns({ with: user, canAccessBB })}
                    showHeader={false}
                    summary={() => (
                        <BudgetTableSummary
                            withStyling={false}
                            items={{
                                0: (
                                    <span className="budget-sheet__meta-table-total">
                                        Total {type === "cost" ? "costs" : "revenues"}
                                    </span>
                                ),
                                6: (
                                    <ObscuredBBWrapper placement="topRight">
                                        <span className="budget-sheet__meta-table-total">
                                            {renderPremiumValue(
                                                type === "cost"
                                                    ? formatCurrency(
                                                          data?.total_costs_curr,
                                                          user?.location?.ccy,
                                                          0
                                                      )
                                                    : formatCurrency(
                                                          data?.total_revenues_curr,
                                                          user?.location?.ccy,
                                                          0
                                                      )
                                            )}
                                        </span>
                                    </ObscuredBBWrapper>
                                ),
                                9: (
                                    <span className="budget-sheet__meta-table-total">
                                        {type === "cost"
                                            ? formatCurrency(
                                                  data?.total_costs_proj,
                                                  user?.location?.ccy,
                                                  0
                                              )
                                            : formatCurrency(
                                                  data?.total_revenues_proj,
                                                  user?.location?.ccy,
                                                  0
                                              )}
                                    </span>
                                ),
                            }}
                            classNames={{
                                5: "budget-sheet__border-bottom-left-cell",
                                9: "budget-sheet__border-bottom-right-cell",
                                8: "budget-sheet__border-left-cell",
                            }}
                        />
                    )}
                />
            </>
        );
    };

    return (
        <div className="budget-sheet">
            <div className="budget-sheet__revenues table__wrap">
                {renderBudgeSheetByType("revenue")}
            </div>
            <div className="budget-sheet__costs table__wrap">{renderBudgeSheetByType("cost")}</div>

            <Table
                className="budget-sheet__meta-table budget-sheet__meta-table--collective"
                columns={budget_columns({ with: user, canAccessBB })}
                showHeader={false}
                summary={() => (
                    <BudgetTableSummary
                        items={{
                            0: <span className="budget-sheet__meta-table-total">Total</span>,
                            6: (
                                <ObscuredBBWrapper placement="topRight">
                                    <span className="budget-sheet__meta-table-total">
                                        {renderPremiumValue(
                                            formatCurrency(data?.total_curr, user?.location?.ccy, 0)
                                        )}
                                    </span>
                                </ObscuredBBWrapper>
                            ),
                            9: (
                                <span className="budget-sheet__meta-table-total">
                                    {renderPremiumValue(
                                        formatCurrency(data?.total_proj, user?.location?.ccy, 0)
                                    )}
                                </span>
                            ),
                        }}
                        withStyling={false}
                    />
                )}
            />

            <ProTooltip condition={canAccessBB}>
                <Button
                    className="ant-btn-primary-outline"
                    type="default"
                    style={{ marginTop: 20 }}
                    onClick={() => setExportModalVisible(true)}
                    disabled={!canAccessBB}
                >
                    Export budget...
                </Button>
            </ProTooltip>

            <ExportBudgetModal
                isVisible={exportModalVisible}
                closeModalHandler={() => setExportModalVisible(false)}
                budget={data}
            />

            <AddEditBudgetItemModal
                item={editingItem}
                mode="edit"
                type={editingItem?.category?.type as "cost" | "revenue"}
                isVisible={!!editingItemPk}
                closeModalHandler={() => setEditingItemPk(null)}
            />
        </div>
    );
};

export default BudgetSheet;
