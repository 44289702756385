import { ReactElement } from "react";
import { Field } from "formik";
import { Button, Typography, Tooltip } from "antd";
import {
    FormikInput,
    FormikNumber,
    FormikSelect,
    FormikTimePicker,
} from "app/components/elements/Formik";
import { RaceFormDataTypes } from "app/store/races/races.types";
import userSlice from "app/store/user/user.slice";
import "app/components/templates/add_edit_race/add_edit_race.scss";
import { BinOutlined } from "assets";

// TODO -> Correct item type
const RaceEvent = ({
    index,
    item,
    distance_units,
    participants,
    setFieldValue,
    deleteEvent,
    errors,
    isLastItem,
}: {
    index: number;
    item: any;
    participants: RaceFormDataTypes["participants"];
    distance_units: RaceFormDataTypes["distance_units"];
    setFieldValue: (fieldName: string, value: string | null) => void;
    deleteEvent: () => void;
    errors: any;
    isLastItem: boolean;
}): ReactElement => {
    const { user } = userSlice((state) => state);

    return (
        <div className="add-edit-race__event-item " key={item.pk || item.id}>
            <div className="add-edit-race__event-input add-edit-race__event-name">
                <Field
                    label="Event name"
                    name={`events.${index}.name`}
                    required
                    className="eventinputs-event-name eventinput"
                    component={FormikInput}
                />
            </div>
            <div className="add-edit-race__event-input add-edit-race__event-distance">
                <Typography.Text className="ant-label" strong>
                    Event distance <span className="ant-label-required">*</span>
                </Typography.Text>
                <div className="add-edit-race__content-group">
                    <Field
                        name={`events.${index}.distance`}
                        required
                        className="eventinputs-distance eventinput add-edit-race__event-distance-input"
                        // hasStepper={false}
                        component={FormikNumber}
                        setFieldValue={setFieldValue}
                    />
                    <Field
                        name={`events.${index}.distance_units`}
                        required
                        options={distance_units}
                        className="eventinputs-unit eventinput"
                        component={FormikSelect}
                        searchable
                        optionFilterProp="label"
                    />
                </div>
            </div>
            <div className="add-edit-race__event-input add-edit-race__participants">
                <Field
                    label="Participants"
                    required
                    name={`events.${index}.participants`}
                    options={participants}
                    className="eventinputs-participants eventinput"
                    component={FormikSelect}
                />
            </div>
            <div className="add-edit-race__event-input add-edit-race__start-time">
                <Field
                    label="Start time"
                    className="time-picker"
                    name={`events.${index}.start_time`}
                    setFieldValue={setFieldValue}
                    component={FormikTimePicker}
                    required
                />
            </div>
            <div className="add-edit-race__event-input add-edit-race__entry-fee">
                <Field
                    label="Entry fee"
                    name={`events.${index}.entry_fee`}
                    prefix={user?.location?.ccy_symbol}
                    className={`eventinputs-entry-fee eventinput ${
                        errors?.events?.[index]?.hasOwnProperty("entry_fee") ? "hasError" : ""
                    }`}
                    component={FormikNumber}
                    required
                    setFieldValue={setFieldValue}
                />
            </div>
            {!isLastItem && (
                <div className="add-edit-race__delete-event">
                    <Tooltip title="Delete this event" placement="bottom">
                        <Button
                            type="text"
                            onClick={() => deleteEvent()}
                            className="ant-custom-btn-icon"
                            danger
                        >
                            <BinOutlined />
                        </Button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
export default RaceEvent;
