/* eslint-disable react/no-danger */
import { ReactElement, useEffect, useRef } from "react";
import { Button, Modal, Typography } from "antd";
import { Field, Formik, FormikProps } from "formik";
import { _isEmpty } from "app/utils/helpers";
import { Option } from "app/components/elements/form/select/select.types";
import { WidgetRaceType } from "app/types/races/race.types";
import Dialog from "app/components/elements/dialog/dialog";
import useClaimOffer from "app/views/protected/plan/offers/useClaimOffer";
import { FormikInput, FormikSelect, FormikTextArea } from "app/components/elements/Formik";
import { claimOfferValidation } from "app/lib/validation_schemas/claim_offer_validation.schema";
import { PhoneOutlined } from "assets";

const { Text, Title } = Typography;

type FormInputsType = {
    message: "";
    phone_number: "";
    race: {
        name: string;
        value: {
            name: string;
            pk: number;
        };
    } | null;
};

const VendorOffersModal = ({
    pk,
    title,
    sponsor,
    content,
    claimMode,
    isVisible,
    claimInstructions,
    footerVisible,
    cta,
    widgetRaces,
    conditions,
    closeModal,
    onSuccess,
}: {
    pk: number;
    title: string;
    cta: string;
    content: string;
    sponsor: string;
    isVisible: boolean;
    claimMode: "quote" | "instructions";
    claimInstructions: string;
    footerVisible: boolean;
    conditions: string[];
    widgetRaces: Partial<WidgetRaceType>[];
    onSuccess: (success: string) => void;
    closeModal: () => void;
}): ReactElement => {
    const { isLoading, success, error, handler, clearClaimOffer } = useClaimOffer(pk);
    const formRef = useRef<FormikProps<FormInputsType>>(null);

    const claimOfferAction = async (formData: FormInputsType) => {
        const response = await handler({
            content: formData.message,
            author_phone: formData.phone_number,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            race: formData.race ? { pk: formData.race!.value.pk } : null,
            source: "dashboard_offers",
        });

        if (response) {
            closeModal();
            onSuccess("Your offer claim has been submitted");
        }
    };

    const checkForUpcomingRace = (): boolean | Option =>
        widgetRaces?.some((race) => race.groupLabel === "upcoming");

    const renderSelectOptions = () =>
        !_isEmpty(widgetRaces) &&
        widgetRaces
            .filter((race) => race.groupLabel === "upcoming")
            .map((race) => ({ name: race.name, value: { pk: race.pk, name: race.name } }));

    const renderConditionsList = (conditions: string[]): JSX.Element[] =>
        conditions?.map((condition: string) => <li key={condition}>{condition}</li>);

    const renderClaimModeContent = (): JSX.Element => {
        if (claimMode === "quote") {
            return (
                <Formik
                    validateOnChange
                    validateOnMount
                    initialValues={{
                        message: "",
                        phone_number: "",
                        race: null,
                    }}
                    validationSchema={claimOfferValidation}
                    onSubmit={claimOfferAction}
                    innerRef={formRef}
                >
                    {({
                        values,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        setTouched,
                    }) => (
                        <div className="vendor-offers-modal__claim">
                            <div className="vendor-offers-modal__form-group">
                                <Field
                                    name="message"
                                    required
                                    label={`Your message to ${sponsor}`}
                                    component={FormikTextArea}
                                    rows="4"
                                />
                            </div>

                            <div className="vendor-offers-modal__form-group">
                                <Field
                                    name="phone_number"
                                    label="Your phone number"
                                    required
                                    component={FormikInput}
                                    size="lg"
                                    prefix={<PhoneOutlined />}
                                />
                            </div>
                            {checkForUpcomingRace() && (
                                <div className="vendor-offers-modal__form-group">
                                    <Field
                                        name="race"
                                        label="Race this claim request relates to"
                                        component={FormikSelect}
                                        options={renderSelectOptions()}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Formik>
            );
        }
        return (
            <div className="vendor-offers-modal__instructions-mode vendor-offers-modal__claim">
                <Title level={4}>To claim this offer:</Title>
                <div
                    className="vendor-offers-modal__html-content"
                    dangerouslySetInnerHTML={{ __html: claimInstructions }}
                />
            </div>
        );
    };

    // Close modal
    const closeModalHandler = (): void => {
        if (formRef.current?.dirty) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(
                "Are you sure you want to close this modal? Any unsaved changes will be lost."
            );

            if (!confirm) {
                return;
            }
        }

        clearClaimOffer();
        closeModal();
    };

    const dialogRef = useRef<HTMLDivElement>(null);

    // scroll to the dialog message upon success or error
    useEffect(() => {
        if (!_isEmpty(success) || !_isEmpty(error)) {
            dialogRef.current?.scrollIntoView();
        }
    }, [success, error]);

    return (
        <Modal
            open={isVisible}
            onCancel={closeModalHandler}
            title={title}
            destroyOnClose
            footer={
                footerVisible ? (
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            if (formRef.current) {
                                formRef.current.handleSubmit();
                            }
                        }}
                        loading={isLoading}
                    >
                        {cta || "Claim offer"}
                    </Button>
                ) : null
            }
            width={480}
            centered
        >
            <div ref={dialogRef}>
                {!_isEmpty(error) && (
                    <div className="vendor-offers-modal__dialog">
                        <Dialog type="error" message={error} />
                    </div>
                )}
            </div>
            <div className="vendor-offers-modal">
                <div
                    className="vendor-offers-modal__content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {conditions?.length !== 0 && (
                    <ul className="vendor-offers-modal__conditions">
                        {renderConditionsList(conditions)}
                    </ul>
                )}
                <div className="vendor-offers-modal__form-content">{renderClaimModeContent()}</div>
            </div>
        </Modal>
    );
};

export default VendorOffersModal;
