import { ReactElement, useRef, useState, useMemo, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { message, Button, Typography, Upload, UploadFile, UploadProps, Row, Col } from "antd";
import {
    Field,
    FieldArray,
    Form,
    Formik,
    FormikHelpers,
    FormikTouched,
    FieldProps,
    useFormikContext,
    FormikProps,
} from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "app/store/types/user.types";
import racesSlice from "app/store/races/races.slice";
import { RaceDetailsType } from "app/types/races/race.types";
import {
    FormikInput,
    FormikSelect,
    FormikTextArea,
    FormikDatePicker,
} from "app/components/elements/Formik";
import { raceValidationSchema } from "app/lib/validation_schemas/race_validation.schema";
import { _isEmpty, imageValidator } from "app/utils/helpers";
import { RaceFormDataTypes } from "app/store/races/races.types";
import { Prompt } from "app/hooks";
import { detectChangedRaceData } from "app/utils/helpers/detect_changed_race_data";
import { deepEqual } from "app/utils/helpers/deep_equal";
import URL from "app/constants/route_urls";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import { useCreateRace, useUpdateRace } from "app/utils/api/mutations/races.mutation";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import { InternetOutlined, PlusOutlined } from "assets";
import { getBase64 } from "app/utils/helpers/getBase64";
import { groupBy } from "app/utils/helpers/group_by";
import { QUERY_KEYS } from "app/utils/api/queries/constants";
import RaceEvent from "./race_event";
import "app/components/templates/add_edit_race/add_edit_race.scss";

const ComponentWithFormikContext = ({
    initialData,
    values,
    isDirty,
    scrollToErrors,
}: {
    initialData: RaceDetailsType;
    values: RaceDetailsType;
    isDirty: boolean;
    scrollToErrors: (arg: string[]) => void;
}): ReactElement => {
    const { errors, isSubmitting } = useFormikContext();

    const detectMemoizedChanges = useMemo(() => {
        const detectChanges = deepEqual(initialData, values);
        return !detectChanges;
    }, [initialData, values]);

    useEffect(() => {
        if (isSubmitting && Object.keys(errors).length > 0) {
            setTimeout(() => {
                scrollToErrors(errors as string[]);
            }, 100);
        }
    }, [isSubmitting, errors, scrollToErrors]);

    return (
        <Prompt
            when={detectMemoizedChanges && !isSubmitting && isDirty}
            message="Are you sure you want to leave this page? Any unsaved changes will be lost."
            beforeUnload
        />
    );
};

const AddEditRaceError = ({ children }: { children: ReactNode }) => (
    <div className="add-edit-race">
        <Typography.Title level={1}>Add/Edit Race</Typography.Title>
        <div className="add-edit-race__error">{children}</div>
    </div>
);

// TODO -> Extract the 'image upload' logic to another component
const AddEditRace = ({
    mode,
    user,
    formData,
    raceData,
}: {
    mode: "edit" | "add" | "copy";
    user: IUser;
    formData: Partial<RaceFormDataTypes>;
    raceData: RaceDetailsType;
}): ReactElement => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const addEditRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const redirectAfterSubmit: string =
        location?.state?.then || Storage.get(STORAGE_CONSTANTS.addRaceRedirect) || URL.RACES;
    const redirectAfterEdit: string | null = Storage.get(STORAGE_CONSTANTS.editRaceRedirect);
    const [withRedirect, setWithRedirect] = useState<boolean>(false);
    const [savingRaceIsLoading, setSavingRaceIsLoading] = useState<boolean>(false);
    const { mutateAsync: createRace, data } = useCreateRace({});
    const { mutateAsync: updateRace } = useUpdateRace({});
    const { clearImportedRace, importedRace } = racesSlice((state) => state);

    const formikRef = useRef<FormikProps<RaceDetailsType>>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    // Populate logo url if it already exists
    useEffect(() => {
        if (formikRef.current?.values.logo?.url) {
            setFileList([{ url: formikRef.current?.values.logo.url, uid: "1", name: "" }]);
        }
    }, [formikRef.current?.values.logo]);

    const handleLogoChange: UploadProps["beforeUpload"] = async (file): Promise<boolean> => {
        const [isValid, errorMessage] = imageValidator(file);
        if (!isValid) {
            formikRef.current?.setFieldError("logo", errorMessage);
            return false;
        }

        const fileUrl = await getBase64(file);
        const prepareFile = {
            uid: file.uid,
            name: file.name,
            url: fileUrl,
        };
        setFileList([prepareFile]);
        formikRef.current?.setFieldValue("logo", {
            base64: prepareFile.url,
        });

        return false;
    };

    useEffect(() => {
        addEditRef?.current?.classList.add("dataHasChanged");
        setTimeout(() => {
            addEditRef?.current?.classList.remove("dataHasChanged");
        }, 750);
    }, [raceData?.name]);

    const eventAddHandler = (push: (val: object) => void): void => {
        push({ title: "" });
    };

    const { handleSelectRaceString } = useRacesWidget();

    const submitRace = async (
        values: RaceDetailsType,
        actions: FormikHelpers<RaceDetailsType>
    ): Promise<void> => {
        const valuesData = values;

        const updatedValues = detectChangedRaceData({
            originalData: raceData,
            newData: valuesData,
        });

        setSavingRaceIsLoading(true);

        if (user.location.language === "en-gb")
            Reflect.set(valuesData, "timezone", { pk: 7, code: "BST" });

        try {
            if (mode === "add" || mode === "copy") await createRace({ formData: valuesData });
            if (mode === "edit") await updateRace({ formData: updatedValues });

            if (mode === "edit" && !withRedirect)
                message.success(`${valuesData.name} was successfully updated`);

            if (mode === "edit" && withRedirect)
                Storage.set(STORAGE_CONSTANTS.editRaceSuccess, valuesData.name);

            if (mode === "add" || mode === "copy")
                Storage.set(STORAGE_CONSTANTS.addRaceSuccess, valuesData.name);

            if (data && mode === "add") {
                const { pk, name } = data.result;
                handleSelectRaceString(JSON.stringify({ pk, name, type: "upcoming" }));
            }

            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RACES, "upcoming", 1] });

            const redirectTo =
                mode === "add" || mode === "copy" ? redirectAfterSubmit : redirectAfterEdit;

            if (importedRace?.race) clearImportedRace();
            if (withRedirect) navigate(redirectTo as string);
        } catch (error) {
            if (error?.response?.status === 500) {
                message.error("Something went wrong while submitting your data.");
                setSavingRaceIsLoading(false);
                return;
            }

            if (error?.response) {
                if (
                    Reflect.has(error.response.data, "non_field_errors") &&
                    typeof error.response.data.non_field_errors === "object"
                ) {
                    error.response.data.non_field_errors.forEach((error: any) =>
                        message.error(error)
                    );
                }

                Object.entries(error.response.data).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        actions.setFieldError(key, value[0]);
                        if (key === "events") {
                            value.forEach((ev: any, index) => {
                                if (Reflect.ownKeys(ev).length !== 0) {
                                    Reflect.ownKeys(ev).forEach((eventKey) => {
                                        actions.setFieldError(
                                            `events.${index}.${String(eventKey)}`,
                                            ev[eventKey][0]
                                        );
                                    });
                                }
                            });
                        }
                    }

                    setSavingRaceIsLoading(false);
                });
            }
        }

        actions.setSubmitting(false);
        setSavingRaceIsLoading(false);

        if (mode === "edit" && !_isEmpty(redirectAfterEdit))
            Storage.remove(STORAGE_CONSTANTS.editRaceRedirect);
    };

    const renderPageHeader = (): JSX.Element => {
        if (mode === "edit") return <Typography.Title level={1}>{raceData?.name}</Typography.Title>;
        return <Typography.Title level={1}>Add race</Typography.Title>;
    };

    const scrollToOnErrors = (errors: string[]): void => {
        const errorsOrder = [
            "name",
            "start_date",
            "type",
            "description",
            "website",
            "registration_page",
            "logo",
            "address",
            "city",
            "region",
            "postal_code",
            "timezone",
        ];
        try {
            const topNavigation = document.querySelector(".top-navbar__header") as HTMLElement;
            if (!topNavigation) throw new Error("Can't find top navigation element");

            const topNavigationHeight = topNavigation.offsetHeight;
            const errorKeys: string[] = Object.keys(errors);
            const firstError = errorsOrder.find((error) => errorKeys.includes(error));
            const element = document.querySelector(`[data-error=${firstError}]`) as HTMLDivElement;

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: elementPosition - (topNavigationHeight + 30),
                    behavior: "smooth",
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const renderFormCta = (submitForm: () => void): JSX.Element => (
        <div className="add-edit-race__form-cta">
            <Button
                type="primary"
                loading={withRedirect && savingRaceIsLoading}
                disabled={savingRaceIsLoading}
                className="responsive-cta"
                onClick={async () => {
                    submitForm();
                    setWithRedirect(true);
                }}
            >
                <span>Save</span>
            </Button>

            {mode === "edit" && (
                <Button
                    loading={!withRedirect && savingRaceIsLoading}
                    disabled={savingRaceIsLoading}
                    className="responsive-cta ant-btn-primary-outline"
                    type="default"
                    onClick={async () => {
                        submitForm();
                        setWithRedirect(false);
                    }}
                >
                    <span>Save & continue editing</span>
                </Button>
            )}
        </div>
    );

    if (mode === "edit" && new Date(raceData?.start_date) < new Date()) {
        return (
            <AddEditRaceError>
                You cannot edit "{raceData?.name}" since it's a past race.
            </AddEditRaceError>
        );
    }

    const raceTypeOptions = formData?.types ? groupBy(formData?.types, "discipline") : [];

    return (
        <Formik
            key={raceData?.name}
            validateOnChange
            initialValues={raceData}
            initialTouched={Object.keys(raceData).reduce((acc, key) => {
                if (raceData[key]) acc[key] = true;
                return acc;
            }, {} as FormikTouched<RaceDetailsType>)}
            validationSchema={() => raceValidationSchema(user?.location.language)}
            onSubmit={(values, actions) => {
                submitRace(values, actions);
                actions.setSubmitting(true);
            }}
            innerRef={formikRef}
        >
            {({
                values,
                errors,
                // isSubmitting,
                submitForm,
                setFieldValue,
                setFieldError,
                dirty,
            }) => (
                <div className="add-edit-race-wrap" ref={addEditRef}>
                    <Form className="add-edit-race">
                        <ComponentWithFormikContext
                            initialData={raceData}
                            values={values}
                            scrollToErrors={(errors: string[]) => scrollToOnErrors(errors)}
                            isDirty={dirty}
                        />

                        <div className="add-edit-race__form-wrap">
                            <div className="add-edit-race__form-item">
                                <div className="add-edit-race__race-information add-edit-race__card">
                                    <div className="add-edit-race__top-wrap">
                                        <div className="add-edit-race__top-item">
                                            {renderPageHeader()}
                                            <div className="add-edit-race__required-fields-info">
                                                <Typography.Text>
                                                    Fields marked with (
                                                    <span className="add-edit-race__required">
                                                        *
                                                    </span>
                                                    ) are required
                                                </Typography.Text>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-edit-race__card-top">
                                        <Typography.Title level={4}>General info</Typography.Title>
                                    </div>
                                    <div className="add-edit-race__content">
                                        <Row gutter={[25, 8]} style={{ marginBottom: "2rem" }}>
                                            <Col span={24} md={8} data-error="name">
                                                <Field
                                                    label="Race name"
                                                    name="name"
                                                    required
                                                    component={FormikInput}
                                                />
                                            </Col>
                                            <Col span={24} md={8} data-error="start_date">
                                                <Field
                                                    label="Race date"
                                                    name="start_date"
                                                    required
                                                    minDate={new Date()}
                                                    setFieldValue={(
                                                        key: string,
                                                        value: Date | null
                                                    ) => setFieldValue(key, value)}
                                                    component={FormikDatePicker}
                                                />
                                            </Col>
                                            <Col span={24} md={8} data-error="type">
                                                <Field
                                                    label="Race type"
                                                    name="type"
                                                    required
                                                    component={FormikSelect}
                                                    options={raceTypeOptions}
                                                    hidePlaceholder
                                                />
                                            </Col>
                                        </Row>

                                        <Row
                                            gutter={[25, 8]}
                                            style={{ marginBottom: "2rem" }}
                                            data-error="description"
                                        >
                                            <Col span={24}>
                                                <Field
                                                    label="Race description"
                                                    name="description"
                                                    required
                                                    component={FormikTextArea}
                                                />
                                            </Col>
                                        </Row>

                                        <Row
                                            gutter={[25, 8]}
                                            style={{ marginBottom: "2rem" }}
                                            data-error="website"
                                        >
                                            <Col span={24} md={12}>
                                                <Field
                                                    label="Race website"
                                                    name="website"
                                                    required
                                                    component={FormikInput}
                                                    prefix={<InternetOutlined />}
                                                />
                                            </Col>

                                            <Col span={24} md={12} data-error="registration_page">
                                                <Field
                                                    label="Race registration page"
                                                    name="registration_page"
                                                    required
                                                    component={FormikInput}
                                                    prefix={<InternetOutlined />}
                                                />
                                            </Col>
                                        </Row>

                                        <Row gutter={[25, 8]}>
                                            <Col
                                                span={24}
                                                className="add-edit-race__logo-column"
                                                data-error="logo"
                                            >
                                                <Field name="logo">
                                                    {({ field }: FieldProps) => (
                                                        <div className="add-edit-race__logo-image-wrap">
                                                            <Typography.Text
                                                                strong
                                                                className="ant-label"
                                                                data-label="upload-label"
                                                            >
                                                                Race logo (
                                                                <span className="ant-label-required">
                                                                    *
                                                                </span>
                                                                )
                                                            </Typography.Text>

                                                            <div
                                                                className={`upload-dragger-wrap ${
                                                                    errors?.logo ? "isError" : ""
                                                                }`}
                                                            >
                                                                <Upload
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    maxCount={1}
                                                                    listType="picture-card"
                                                                    multiple={false}
                                                                    fileList={fileList}
                                                                    onRemove={() => {
                                                                        setFileList([]);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            undefined
                                                                        );
                                                                    }}
                                                                    beforeUpload={handleLogoChange}
                                                                    showUploadList={{
                                                                        showPreviewIcon: false,
                                                                    }}
                                                                    onChange={(info) => {
                                                                        if (
                                                                            info.fileList.length ===
                                                                            0
                                                                        ) {
                                                                            setFieldError(
                                                                                field.name,
                                                                                "Logo is required"
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <button
                                                                        className="business-listing__upload-btn"
                                                                        type="button"
                                                                    >
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{ marginTop: 8 }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </button>
                                                                </Upload>

                                                                <Typography.Text>
                                                                    Max file size: 2mb | Accepted:
                                                                    .jpg, .jpeg, .png
                                                                </Typography.Text>
                                                            </div>

                                                            <Typography.Text className="ant-error-label">
                                                                {errors.logo}
                                                            </Typography.Text>
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="add-edit-race__race-location add-edit-race__card">
                                    <div className="add-edit-race__card-top">
                                        <Typography.Title level={4}>Location</Typography.Title>
                                    </div>
                                    <div className="add-edit-race__content">
                                        <Row gutter={[25, 8]} style={{ marginBottom: "2rem" }}>
                                            <Col span={24} md={12} data-error="venue">
                                                <Field
                                                    label="Venue"
                                                    name="venue"
                                                    component={FormikInput}
                                                />
                                            </Col>
                                            <Col span={24} md={12} data-error="address">
                                                <Field
                                                    label="Address"
                                                    name="address"
                                                    required
                                                    component={FormikInput}
                                                />
                                            </Col>
                                        </Row>

                                        <Row gutter={[25, 8]}>
                                            <Col span={24} md={6} data-error="city">
                                                <Field
                                                    label="City"
                                                    name="city"
                                                    required
                                                    className="add-race__city-input"
                                                    component={FormikInput}
                                                />
                                            </Col>
                                            <Col span={24} md={6}>
                                                <Field
                                                    label={t("form.region")}
                                                    name="region"
                                                    required
                                                    options={formData?.regions}
                                                    className="add-race__region-input"
                                                    component={FormikSelect}
                                                    searchable
                                                />
                                            </Col>
                                            <Col span={24} md={6} data-error="postal_code">
                                                <Field
                                                    type="text"
                                                    label={t("form.postal_code")}
                                                    name="postal_code"
                                                    component={FormikInput}
                                                    className="add-race__postal-code-input"
                                                    required
                                                />
                                            </Col>
                                            {user?.location.language !== "en-gb" && (
                                                <Col span={24} md={6} data-error="timezone">
                                                    <Field
                                                        label="Timezone"
                                                        name="timezone"
                                                        required
                                                        hidden
                                                        options={formData?.timezones}
                                                        className="add-race__timezone-input"
                                                        component={FormikSelect}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <div className="add-edit-race__form-item">
                                <div className="add-edit-race__race-events add-edit-race__card">
                                    <div className="add-edit-race__card-top">
                                        <Typography.Title level={4}>Events</Typography.Title>
                                    </div>
                                    <div
                                        className="add-edit-race__events-wrapper"
                                        style={{ marginTop: 0 }}
                                        data-error="events"
                                    >
                                        <FieldArray name="events">
                                            {({ push, remove }) => (
                                                <>
                                                    <div className="add-edit-race__events">
                                                        {values.events.map(
                                                            (event, index: number) => (
                                                                <RaceEvent
                                                                    errors={errors}
                                                                    index={index as number}
                                                                    key={index as number}
                                                                    item={event}
                                                                    setFieldValue={setFieldValue}
                                                                    deleteEvent={() =>
                                                                        remove(index)
                                                                    }
                                                                    participants={
                                                                        formData.participants
                                                                    }
                                                                    distance_units={
                                                                        formData.distance_units
                                                                    }
                                                                    isLastItem={
                                                                        values.events.length === 1
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="add-edit-race__event-action">
                                                        <button
                                                            onClick={() => eventAddHandler(push)}
                                                            className="link"
                                                            type="button"
                                                        >
                                                            Add event
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                </div>
                            </div>

                            <div className="add-edit-race__form-cta-container">
                                {renderFormCta(submitForm)}
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default AddEditRace;
